import {createAction, props} from '@ngrx/store';
import {
  BankkontoDTO as FakturaBankkonto,
  EinstellungenResponseDTO,
  KontaktDTO
} from '../../openapi/fakturierung-openapi';
import {AdressartDTO, FirmaExtendedReadResponseDTO} from '../../openapi/partner-openapi';
import {FinanzamtDTO} from '../../openapi/schluesseldaten-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {BankkontoCreateRequestDTO} from '../../openapi/zahlung-openapi';


export class FirmaEditActions {

  public static updateFirmaUpdateRequestDto = createAction(
    '[Edit] Update the firma-update-request-dto.',
    props<{
      firmaDto: FirmaExtendedReadResponseDTO,
    }>(),
  );

  public static updateEinstellungenRequestDto = createAction(
    '[Firma Edit] Update the einstellungen-request-dto.',
    props<{
      einstellungenResponseDto: EinstellungenResponseDTO,
    }>(),
  );

  public static updateEinstellungenRequestDtoBankkontoId = createAction(
    '[Edit] Update the bankkonto-id at einstellungen-request-dto.',
    props<{
      bankkontoId: string | undefined,
    }>(),
  );

  public static updateBankkontoCreateRequestDto = createAction(
    '[Edit] Update the bankkonto-create-request-dto.',
    props<{
      bankkontoCreateRequestDTO: BankkontoCreateRequestDTO | undefined,
    }>(),
  );

  public static updateFirmenbezeichnung = createAction(
    '[Edit] Update firmenbezeichnung.',
    props<{
      firmenbezeichnung: string,
    }>(),
  );

  public static updateGeschaeftstaetigkeit = createAction(
    '[Edit] Update Geschäftstätigkeit.',
    props<{
      geschaeftstaetigkeit: string | undefined,
    }>(),
  );


  public static updateAdressart = createAction(
    '[Edit] Update adressart.',
    props<{
      adressartDTO: AdressartDTO,
    }>(),
  );


  public static updateStrasse = createAction(
    '[Edit] Update strasse.',
    props<{
      strasse: string,
    }>(),
  );

  public static updatePostfach = createAction(
    '[Edit] Update postfach.',
    props<{
      postfach: string,
    }>(),
  );

  public static updatePlz = createAction(
    '[Edit] Update plz.',
    props<{
      plz: string,
    }>(),
  );

  public static updateOrt = createAction(
    '[Edit] Update ort.',
    props<{
      ort: string,
    }>(),
  );

  public static updateLand = createAction(
    '[Edit] Update land.',
    props<{
      land: string,
    }>(),
  );

  public static updateSelectedFinanzamtByFinanzamtsschluessel = createAction(
    '[Edit] Update the selected finanzamt-dto.',
    props<{
      previousFinanzamtsschluessel: number | undefined,
      selectedFinanzamtDto: FinanzamtDTO,
    }>(),
  );

  public static updateSteuernummerByFinanzamtsschluessel = createAction(
    '[Edit] Update steuernummer.',
    props<{
      steuernummer: string,
      finanzamtsschluessel: number,
    }>(),
  );

  public static updateUmsatzsteuerId = createAction(
    '[Edit] Update umsatzsteuer-id.',
    props<{
      umsatzsteuerIdNummer?: string,
    }>(),
  );

  public static updateGlaeubigerId = createAction(
    '[Edit] Update glaeubiger-id.',
    props<{
      glaeubigerId?: string,
    }>(),
  );

  public static updateDeinBetriebBlockValidity = createAction(
    '[Edit] Update dein-betrieb-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateBetriebsadresseBlockValidity = createAction(
    '[Edit] Update betriebsadresse-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateBetriebsinformationenBlockValidity = createAction(
    '[Edit] Update betriebsinformationen-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateSelectedBankInformation = createAction(
    '[Edit] Update selected bankkonto.',
    props<{
      bankkontoDto: FakturaBankkonto | undefined,
    }>(),
  );

  public static updateKontaktBlockData = createAction(
    '[Edit] Update kontakt-dto.',
    props<{
      kontakt: KontaktDTO,
    }>(),
  );

  public static updateKontaktBlockValidity = createAction(
    '[Edit] Update kontakt-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static updateBankkontoBlockValidity = createAction(
    '[Edit] Update bankkonto-block validity.',
    props<{
      valid: boolean,
    }>(),
  );

  public static readEinstellungen = createAction(
    '[Edit] Read einstellungen.',
    props<{ firmaId: string }>(),
  );

  public static readEinstellungenFailure = createAction(
    '[Edit] Read einstellungen failure.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static readBankkonten = createAction(
    '[Edit] Read bankkonten.',
    props<{ firmaId: string }>(),
  );

  public static readBankkontenSuccess = createAction(
    '[Edit] Read bankkonten success.',
    props<{
      bankkonten: FakturaBankkonto[],
    }>(),
  );

  public static readBankkontenFailure = createAction(
    '[Edit] Read bankkonten failure.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static readBankinformation = createAction(
    '[Edit] Read bankinformation.',
    props<{
      iban: string,
    }>(),
  );

  public static readBankinformationFailure = createAction(
    '[Edit] Read bankinformation failure.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

}
