import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppNavigationComponent} from './app-navigation.component';
import {MatButtonModule} from '@angular/material/button';
import {ButtonModule, IconComponent, LinkModule} from '@adnova/jf-ng-components';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';


@NgModule({
  declarations: [
    AppNavigationComponent,
  ],
  exports: [
    AppNavigationComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    IconComponent,
    MatFormFieldModule,
    RouterLink,
    MatDividerModule,
    RouterLinkActive,
    LinkModule,
    LinkModule,
    ButtonModule,
  ]
})
export class AppNavigationModule {
}
